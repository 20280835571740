import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import {
  DEFAULT_COMPOUND_FREQUENCY_CRO,
  DEFAULT_COMPOUND_FREQUENCY_ETH,
  getApy,
  getApyFromApr7d,
} from '@ui/utils/aprToApy';
import {
  ACTIVE_APY_MODE_STRATEGY,
  ACTIVE_COMPOUND_FREQUENCY_PER_DAY_STRATEGY,
  ApyMode,
} from '@ui/utils/constants';
import type { GraphQLClient } from 'graphql-request';

import { QueryKey } from './queryKey';

interface AprResponse {
  liquidCro: {
    apr7d: number | null;
    apr24h: number | null;
  } | null;
}

interface AprResponseLatom {
  liquidAtom: {
    apr7d: number | null;
    apr24h: number | null;
  } | null;
}

interface AprResponseLtia {
  liquidTia: {
    apr7d: number | null;
    apr24h: number | null;
  } | null;
}

interface AprResponseLeth {
  liquidEthDailySnapshots: {
    apr30d: string;
  }[];
}

interface AprResponse {
  liquidCro: {
    apr7d: number | null;
    apr24h: number | null;
  } | null;
}

interface AprResponseStrategy {
  ferroLCroCroLPVault: {
    apr7d: number | null;
    apr24h: number | null;
  } | null;
}

const APR_QUERY = `
query apr {
  liquidCro(id:"1") {
    apr7d
  }
}
`;

const APR_QUERY_LATOM = `
query apr {
  liquidAtom(id:"1") {
    apr7d
    apr24h
  }
}
`;

const APR_QUERY_LTIA = `
query apr {
  liquidTia(id:"1") {
    apr7d
    apr24h
  }
}
`;

const APR_QUERY_LETH = `
  query apr {
    liquidEthDailySnapshots(orderBy: date, orderDirection: desc, first: 1) {
      apr30d
    }
  }
`;

const APR_QUERY_STRATEGY = `
query apr {
  ferroLCroCroLPVault(id:"1") {
    apr7d
    apr24h
  }
}
`;

const APR_QUERY_FERRO_GARDEN = `
query apr {
  ferroGarden(id:"1") {
    apr
    apy
    id
  }
}
`;

interface AprResponseFerroGarden {
  ferroGarden: {
    apr: number | null;
    apy: number | null;
  } | null;
}

type HistoricAprsResponse = {
  snapshots: {
    apr24h: number | null;
    apr7d: number | null;
    date: string;
  }[];
};

type HistoricAprsResponseStrategy = {
  ferroLCroCroLPVaultDailySnapshots: {
    apr24h: number | null;
    apr7d: number | null;
    date: string;
  }[];
};

const HISTORIC_APRS_QUERY = `
query aprs($date: Int!) {
  snapshots: liquidCroDailySnapshots(where:{date_gte: $date}) {
    date
    apr24h
    apr7d
  }
}
`;

const HISTORIC_APRS_QUERY_ATOM = `
query aprs($date: Int!) {
  snapshots: liquidAtomDailySnapshots(where:{date_gte: $date}) {
    date
    apr24h
    apr7d
  }
}
`;

const HISTORIC_APRS_QUERY_ETH = `
query aprs($date: Int!) {
  snapshots: liquidEthDailySnapshots(where:{date_gte: $date}) {
    date
    apr24h
    apr7d
  }
}
`;

const HISTORIC_APRS_QUERY_TIA = `
query aprs($date: Int!) {
  snapshots: liquidTiaDailySnapshots(where:{date_gte: $date}) {
    date
    apr24h
    apr7d
  }
}
`;

const HISTORIC_APRS_QUERY_STRATEGY = `
query aprs($date: Int!) {
  ferroLCroCroLPVaultDailySnapshots(where:{date_gte: $date}) {
    date
    apr24h
    apr7d
  }
}
`;

const HISTORIC_APRS_QUERY_FERRO_GARDEN_BY_DATE = `
query aprs($date: Int!) {
  ferroGardenDailySnapshots(orderBy: date, orderDirection: desc, where:{date_gte: $date}) {
    id
    date
    apy
    apr
  }
}
`;

type HistoricAprsResponseFerroGarden = {
  ferroGardenDailySnapshots: {
    id: string;
    apy: string | null;
    apr: string | null;
    date: string;
  }[];
};

/**
 *
 * @param graphQlClient
 * @returns aprs info for the recent 90 days
 */
export const getHistoricApysQuery = (
  graphQlClient: GraphQLClient,
  mode: CoinMode,
) => ({
  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  queryKey: [QueryKey.HISTORIC_APYS, mode ?? 'cro'],
  queryFn: async () => {
    const subgraphQuery: Record<CoinMode, string> = {
      cro: HISTORIC_APRS_QUERY,
      atom: HISTORIC_APRS_QUERY_ATOM,
      eth: HISTORIC_APRS_QUERY_ETH,
      tia: HISTORIC_APRS_QUERY_TIA,
    };
    const result = await graphQlClient.request<HistoricAprsResponse>(
      subgraphQuery[mode],
      // get data for 90 days
      { date: Math.floor(new Date().getTime() / 1000) - 90 * 24 * 60 * 60 },
    );

    return result.snapshots.map((snapshot) => ({
      date: new Date(parseInt(snapshot.date) * 1000),
      apy:
        COIN_MODE[mode].apyMode === ApyMode.day
          ? getApy(
              snapshot.apr24h ?? 0,
              mode === 'eth'
                ? DEFAULT_COMPOUND_FREQUENCY_ETH
                : DEFAULT_COMPOUND_FREQUENCY_CRO,
            )
          : getApyFromApr7d(
              snapshot.apr7d ?? 0,
              mode === 'eth'
                ? DEFAULT_COMPOUND_FREQUENCY_ETH
                : DEFAULT_COMPOUND_FREQUENCY_CRO,
            ),
    }));
  },
});

/**
 *
 * @param graphQlClient
 * @returns aprs info for the recent 90 days
 */
export const getHistoricFerroGardenApysQuery = (
  graphQlClient: GraphQLClient,
) => ({
  queryKey: ['getHistoricFerroGardenApysQuery'],
  queryFn: async () => {
    // get data for 90 days
    let snapshots: HistoricAprsResponseFerroGarden['ferroGardenDailySnapshots'] =
      [];
    const result = await graphQlClient.request<HistoricAprsResponseFerroGarden>(
      HISTORIC_APRS_QUERY_FERRO_GARDEN_BY_DATE,
      { date: Math.floor(new Date().getTime() / 1000) - 90 * 24 * 60 * 60 },
    );
    snapshots = result.ferroGardenDailySnapshots;
    return snapshots.map((snapshot) => ({
      date: new Date(parseInt(snapshot.date) * 1000),
      apy: Number(snapshot.apy ?? '0'),
    }));
  },
});

/**
 *
 * @param graphQlClient
 * @returns aprs info for the recent 90 days
 */
export const getImmediateHistoricApysStrategyQuery = (
  graphQlClient: GraphQLClient,
) => ({
  queryKey: ['getImmediateHistoricApysStrategyQuery'],
  queryFn: async () => {
    // get data for 90 days
    let snapshots: HistoricAprsResponseStrategy['ferroLCroCroLPVaultDailySnapshots'] =
      [];
    const result = await graphQlClient.request<HistoricAprsResponseStrategy>(
      HISTORIC_APRS_QUERY_STRATEGY,
      { date: Math.floor(new Date().getTime() / 1000) - 90 * 24 * 60 * 60 },
    );
    snapshots = result.ferroLCroCroLPVaultDailySnapshots;
    return snapshots.map((snapshot) => ({
      date: new Date(parseInt(snapshot.date) * 1000),
      apy:
        ACTIVE_APY_MODE_STRATEGY === ApyMode.day
          ? getApy(
              snapshot.apr24h ?? 0,
              ACTIVE_COMPOUND_FREQUENCY_PER_DAY_STRATEGY,
            )
          : getApyFromApr7d(
              snapshot.apr7d ?? 0,
              ACTIVE_COMPOUND_FREQUENCY_PER_DAY_STRATEGY,
            ),
    }));
  },
});

export const getAprQuery = (graphQlClient: GraphQLClient) => ({
  queryKey: [QueryKey.APR, 'cro'],
  queryFn: async () => {
    const result = await graphQlClient.request<AprResponse>(APR_QUERY);
    return result.liquidCro?.apr7d ?? 0;
  },
});

export const getLatomAprQuery = (graphQlClient: GraphQLClient) => ({
  queryKey: [QueryKey.APR, 'atom'],
  queryFn: async () => {
    const result = await graphQlClient.request<AprResponseLatom>(
      APR_QUERY_LATOM,
    );
    return (
      (COIN_MODE.atom.apyMode === ApyMode.day
        ? result.liquidAtom?.apr24h
        : result.liquidAtom?.apr7d) ?? 0
    );
  },
});

export const getLtiaAprQuery = (graphQlClient: GraphQLClient) => ({
  queryKey: [QueryKey.APR, 'tia'],
  queryFn: async () => {
    const result = { liquidTia: { apr24h: 0.095, apr7d: 0.095 } }; // await graphQlClient.request<AprResponseLtia>(APR_QUERY_LTIA);
    return (
      (COIN_MODE.tia.apyMode === ApyMode.day
        ? result.liquidTia?.apr24h
        : result.liquidTia?.apr7d) ?? 0
    );
  },
});

const HARD_CODE_ETH_APR = 0.037821691664408075;

export const getLethAprQuery = (graphQlClient: GraphQLClient) => ({
  queryKey: [QueryKey.APR, 'eth'],
  queryFn: async () => {
    const result = await graphQlClient.request<AprResponseLeth>(APR_QUERY_LETH);
    return (
      Number(result.liquidEthDailySnapshots[0]?.apr30d) || HARD_CODE_ETH_APR
    );
  },
});

export const getStrategyAprQuery = (
  strategyClient: GraphQLClient,
  croClient: GraphQLClient,
) => ({
  queryKey: ['getStrategyAprQuery', 'strategy'],
  queryFn: async () => {
    const result = await strategyClient.request<AprResponseStrategy>(
      APR_QUERY_STRATEGY,
    );

    const stakingAprResult = await croClient.request<AprResponse>(APR_QUERY);

    const ferroGardenResult =
      await strategyClient.request<AprResponseFerroGarden>(
        APR_QUERY_FERRO_GARDEN,
      );

    const ferroGardenApy = Number(ferroGardenResult.ferroGarden?.apy ?? 0);

    const stakingApy = getApyFromApr7d(stakingAprResult.liquidCro?.apr7d ?? 0);

    return {
      strategyApy: ferroGardenApy + stakingApy,
      immediateApr:
        (ACTIVE_APY_MODE_STRATEGY === ApyMode.day
          ? result.ferroLCroCroLPVault?.apr24h
          : result.ferroLCroCroLPVault?.apr7d) ?? 0,
    };
  },
});
