/**
 * 3 compounds per hour → 72 compounds per day
 * @see https://mcoproduct.atlassian.net/browse/VNO-108
 */
export const DEFAULT_COMPOUND_FREQUENCY_CRO = 72;
export const DEFAULT_COMPOUND_FREQUENCY_ETH = 1;

/**
 * Given APR returns APY
 * @param aprAsDecimal APR as Decimal
 * @param compoundFrequency how many compounds per day
 * @param days if other than 365 adjusts (A)PY for period less than a year
 * @returns APY as decimal
 */
export const getApy = (
  aprAsDecimal: number,
  compoundFrequency = DEFAULT_COMPOUND_FREQUENCY_CRO,
  days = 365,
): number => {
  const daysAsDecimalOfYear = days / 365;
  const timesCompounded = 365 * compoundFrequency;
  let apyAsDecimal = aprAsDecimal * daysAsDecimalOfYear;
  if (timesCompounded > 0) {
    apyAsDecimal =
      (1 + aprAsDecimal / timesCompounded) **
        (timesCompounded * daysAsDecimalOfYear) -
      1;
  }
  return apyAsDecimal;
};

export const getApr = (
  apyAsDecimal: number,
  compoundFrequency = DEFAULT_COMPOUND_FREQUENCY_CRO,
  days = 365,
): number => {
  const daysAsDecimalOfYear = days / 365;
  const timesCompounded = 365 * compoundFrequency;
  let aprAsDecimal = apyAsDecimal * daysAsDecimalOfYear;
  if (timesCompounded > 0) {
    aprAsDecimal =
      ((1 + apyAsDecimal) ** (1 / (timesCompounded * daysAsDecimalOfYear)) -
        1) *
      timesCompounded;
  }
  return aprAsDecimal;
};

/**
 * @see https://cronoslabs.slack.com/archives/C04DH9L1WJY/p1671446186291169?thread_ts=1671425395.301819&cid=C04DH9L1WJY
 */
export const getApyFromApr7d = (
  aprFromApy7d: number,
  compoundFrequency?: number,
) => {
  const apy7d = aprFromApy7d * (7 / 365);
  return getApy(getApr(apy7d, compoundFrequency, 7));
};

export const getApyFromApr30 = (
  aprFromApy30: number,
  compoundFrequency?: number,
) => {
  const apy30d = aprFromApy30 * (30 / 365);
  return getApy(getApr(apy30d, compoundFrequency, 30));
};
