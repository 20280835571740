import type { ChakraProps } from '@chakra-ui/react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { TokenType } from '@ui/components/AddTokenButton';
import type { Token } from '@ui/components/TokenIcon';
import { TokenIcon } from '@ui/components/TokenIcon';
import { getContractAddresses } from '@ui/config/base';
import { useSpecificVenoSDK, useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { ApyMode } from '@ui/utils/constants';
import type {
  ChainType,
  LiquidCoinModuleInterface,
  VenoSDK,
  VenoSDKWithoutTransaction,
} from '@veno-app/sdk';
import { currentWallet, getNetworkConfig } from '@veno-app/wallet';
import type { FC } from 'react';
import { createContext, useContext } from 'react';

import { getPathInfo, useRealPathname } from './router';
import { useAtomBalance } from './useAtomBalance';
import { useBalance } from './useBalance';
import { useTiaBalance } from './useTiaBalance';

export type CoinMode = 'cro' | 'atom' | 'eth' | 'tia';
export type CoinModeCro = 'cro' | 'atom' | 'tia';
export const coinModesCro: CoinModeCro[] = ['cro', 'atom', 'tia'];
export type coinModeZksync = 'eth';
export const coinModesZksync: coinModeZksync[] = ['eth'];
export const coinModes: CoinMode[] = ['cro', 'atom', 'eth', 'tia'];

export const COIN_MODE: Record<
  CoinMode,
  {
    coinMode: CoinMode;
    chainType: ChainType;
    name: Uppercase<Token>;
    Icon: FC<ChakraProps>;
    decimals: number;
    liquidName: Uppercase<Token>;
    liquidAddress: string;
    nftAddress: string;
    liquidTokenType: TokenType;
    LiquidIcon: FC<ChakraProps>;
    liquidDecimals: number;
    minStakeAmount: number;
    displayDecimals: number;
    apyMode: ApyMode;
  }
> = {
  cro: {
    coinMode: 'cro',
    chainType: 'cronos',
    name: 'CRO',
    Icon: (props) => {
      return (
        <TokenIcon token="cro" w="32px" h="32px" alt="cro icon" {...props} />
      );
    },
    decimals: 18,
    liquidName: 'LCRO',
    liquidAddress: getContractAddresses().liquidCro,
    nftAddress: getContractAddresses().venoNft,
    liquidTokenType: TokenType.LCRO,
    LiquidIcon: (props) => {
      return (
        <TokenIcon token="lcro" w="32px" h="32px" alt="lcro icon" {...props} />
      );
    },
    liquidDecimals: 18,
    minStakeAmount: 1,
    displayDecimals: 3,
    apyMode: ApyMode.week,
  },
  atom: {
    coinMode: 'atom',
    chainType: 'cronos',
    name: 'ATOM',
    Icon: (props) => {
      return (
        <TokenIcon token="atom" w="32px" h="32px" alt="atom icon" {...props} />
      );
    },
    decimals: 6,
    liquidName: 'LATOM',
    liquidAddress: getContractAddresses().liquidAtom,
    nftAddress: getContractAddresses().atomNft,
    liquidTokenType: TokenType.LATOM,
    LiquidIcon: (props) => {
      return (
        <TokenIcon
          token="latom"
          w="32px"
          h="32px"
          alt="latom icon"
          {...props}
        />
      );
    },
    liquidDecimals: 6,
    minStakeAmount: 0.05,
    displayDecimals: 3,
    apyMode: ApyMode.week,
  },
  eth: {
    coinMode: 'eth',
    chainType: 'zksync',
    name: 'ETH',
    Icon: (props) => {
      return (
        <TokenIcon token="eth" w="32px" h="32px" alt="eth icon" {...props} />
      );
    },
    decimals: 18,
    liquidName: 'LETH',
    liquidAddress: getContractAddresses('zksync').liquidEth,
    nftAddress: getContractAddresses('zksync').venoNft,
    liquidTokenType: TokenType.LETH,
    LiquidIcon: (props) => {
      return (
        <TokenIcon token="leth" w="32px" h="32px" alt="leth icon" {...props} />
      );
    },
    liquidDecimals: 18,
    // TODO -> dont know this yet
    minStakeAmount: 0.00001,
    displayDecimals: 4,
    apyMode: ApyMode.month,
  },
  tia: {
    coinMode: 'tia',
    chainType: 'cronos',
    name: 'TIA',
    Icon: (props) => {
      return (
        <TokenIcon token="tia" w="32px" h="32px" alt="tia icon" {...props} />
      );
    },
    decimals: 6,
    liquidName: 'LTIA',
    liquidAddress: getContractAddresses().liquidTia,
    nftAddress: getContractAddresses().tiaNft,
    liquidTokenType: TokenType.LTIA,
    LiquidIcon: (props) => {
      return (
        <TokenIcon token="ltia" w="32px" h="32px" alt="ltia icon" {...props} />
      );
    },
    liquidDecimals: 6,
    // TODO -> dont know this yet
    minStakeAmount: 0.001,
    displayDecimals: 4,
    apyMode: ApyMode.day,
  },
};

const coinModeContext = createContext<CoinMode | undefined>(undefined);

export const CoinModeProvider = coinModeContext.Provider;

const { useIsZksyncChainId } = currentWallet;

export const useGlobalCoinMode = (): CoinMode => {
  const realPathname = useRealPathname();
  const isZksync = useIsZksyncChainId();
  const pathInfo = getPathInfo(realPathname, isZksync);
  return pathInfo.coinMode;
};

export const useCoinMode = (): CoinMode => {
  const coinModeInContext = useContext(coinModeContext);
  const coinModeInGlobal = useGlobalCoinMode();

  return coinModeInContext ?? coinModeInGlobal;
};

export const useCoinModeBalance = (
  _coinMode?: CoinMode,
  options: UseQueryOptions<number | undefined> = {},
) => {
  const { chainId: chainIdCronos } = getNetworkConfig();
  const { chainId: chainIdZksync } = getNetworkConfig(true);
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;

  const croBalance = useBalance(chainIdCronos, {
    ...options,
    enabled: coinMode === 'cro' ? options.enabled : false,
  });

  const ethBalance = useBalance(chainIdZksync, {
    ...options,
    enabled: coinMode === 'eth' ? options.enabled : false,
  });

  const atomBalance = useAtomBalance({
    ...options,
    enabled: coinMode === 'atom' ? options.enabled : false,
  });

  const tiaBalance = useTiaBalance({
    ...options,
    enabled: coinMode === 'tia' ? options.enabled : false,
  });

  if (coinMode === 'atom') {
    return atomBalance;
  }

  if (coinMode === 'eth') {
    return ethBalance;
  }

  if (coinMode === 'tia') {
    return tiaBalance;
  }

  return croBalance;
};

const SdkModuleMap: Record<CoinMode, keyof VenoSDK> = {
  atom: 'LiquidAtom',
  cro: 'LiquidCro',
  eth: 'LiquidEth',
  tia: 'LiquidTia',
};

export const useLiquidModule = (
  coinMode: CoinMode,
): LiquidCoinModuleInterface => {
  const sdk = useSpecificVenoSDK(COIN_MODE[coinMode].chainType);

  return getLiquidModule(sdk, coinMode);
};

export const useLiquidModuleWithSigner = (
  coinMode: CoinMode,
): LiquidCoinModuleInterface => {
  const sdk = useVenoSDK();

  return getLiquidModule(sdk, coinMode);
};

export const getLiquidModule = (
  sdk: VenoSDKWithoutTransaction,
  coinMode: CoinMode,
): LiquidCoinModuleInterface => {
  const liquidModule = sdk[SdkModuleMap[coinMode]] as LiquidCoinModuleInterface;
  return liquidModule;
};

export const getTokenFromName = (name: Uppercase<Token>): Token => {
  return name.toLowerCase() as Token;
};
